.sounding_table {
    font-size: 14px; /* Adjust font size for the table */
    margin: 30px 20px;
    max-width: 500px !important;
  }

  .table_td {
    padding: 4px 6px 0px !important; 
   
  }
  .input {
    font-size: 14px;
    text-align: right;
    padding: 10px 3px !important;
    font-size: inherit !important;
    line-height: 1 !important;
    margin-right: 10px;
    border-radius: 5px !important;   
  }
  .top_input_container {
    width: 100%;
    padding: 0 30px;
  }
  .top_input {
    width: 300px !important;
  } 

  .sounding_report {
    font-size: 10px;
  }
  .sounding_report tr th {
    padding: 2px;
    border: 1px solid #000;
    width: 80px;
  }
  .lower_btn {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 52px;
    gap:40px;
  }
  .lower_btn button {
     font-size: small;
  }
  @media screen and (max-width: 600px) {
    .lower_btn {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 15px;
      gap:40px;
    }
    .top_input_container {
      width: 100%;
      padding: 10px;
      flex-direction: column;
      gap:20px;
    }
    .sounding_table {
      font-size: 10px; /* Adjust font size for the table */
      margin: 30px 2px;
      max-width: 500px !important;
    }
  }
  .display_mm{
    display: flex;
    justify-content: center;
    align-items: center;
  }