.container {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .row {
    margin-bottom: 15px;
  }
  
  .label {
    font-size: 16px;
    font-weight: 500;
  }
  
  .select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .dateInput {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
    text-align: left;
  }
  
  .table th, .table td {
    padding: 10px;
  }
  
  .table thead th {
    background-color: #6e6e6e;
    color: white;
    font-weight: bold;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:hover {
    background-color: #e9ecef;
  }
  
  .saveButton {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
  }
  