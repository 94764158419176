@media print {
    table {
        border: 1px solid black;
        border-collapse: collapse;
    }

    th, td {
        border: 1px solid black;
    }
}
@page {
    margin: 1cm;
}

.table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-family: Arial, sans-serif;
}
.sounding_table {
   font-size: 10px;/* Adjust font size for the table */
   padding-left: 10px;
    max-width: 350px !important;
    
  }
  .sounding_report tr th {
    padding: 2px;
    border: 1px solid #000;
    width: 80px;
  }

.table th,
.table td {
    border: 1px solid #000;
    padding: 8px;
}

.rowspan {    
    /* writing-mode: vertical-rl; Make the text vertical */
    transform: none; /* Optional: Rotate to make text face correctly */
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    border: 1px solid #000;
    width: 10px !important;
    text-orientation: mixed; /* Ensures proper rendering of characters */
    font-style: italic;
    letter-spacing: 2px;
     
}
tr.bg_style th{
   background-color: rgb(200, 200, 200); 
}

.colspan {
    text-align: center;
    font-weight: bold;
   
}
.tank_name {
   width: 40px !important;
}

.tank_name_header_text {
     margin-right: 11px;
}

.border {
    border: 1px solid #000 !important;
  }

.txt_red {
    color: red;
    font-weight: bold;
}
.barge_name {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  
  .report_date {
    font-weight: bold;
    font-size: 14px;
    text-align: right;
  }

  